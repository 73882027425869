import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

const SAVE_SELECTED_FOLDER_ID = "DOCUMENT/SAVE_SELECTED_FOLDER_ID";
const SAVE_LIST_DOCUMENTS = "DOCUMENT/SAVE_LIST_DOCUMENTS";
const SAVE_LIST_DOCUMENTS_PAGING = "DOCUMENT/SAVE_LIST_DOCUMENTS_PAGING";
const SAVE_LIST_BREDSCRUMS = "DOCUMENT/SAVE_LIST_BREDSCRUMS";
const SAVE_FOLDER_ID = "DOCUMENT/SAVE_FOLDER_ID";
const SAVE_DATA_SEARCH = "DOCUMENT/SAVE_DATA_SEARCH";



const saveSelectedFolderToRedux = (data) => ({
    type: SAVE_SELECTED_FOLDER_ID,
    data: data,
});

const saveListDocuments = (data) => ({
    type: SAVE_LIST_DOCUMENTS,
    data: data,
});

const saveListDocumentsPaging = (data) => ({
    type: SAVE_LIST_DOCUMENTS_PAGING,
    data: data,
});

const saveListBredScrums = (data) => ({
    type: SAVE_LIST_BREDSCRUMS,
    data: data,
});

const saveFolderId = (data) => ({
    type: SAVE_FOLDER_ID,
    data: data,
});

const saveDataSearch = (data) => ({
    type: SAVE_DATA_SEARCH,
    data: data,
});



export const setCurrentFolderId = (folderId) => {
    return (dispatch) => {
        dispatch(saveFolderId(folderId));
    }
}

export const SetDataSearch = (searchData) => {
    return (dispatch) => {
        dispatch(saveDataSearch(searchData));
    }
}

export  const doSearchDocument = (folderId,pageIndex,pageSize) => {
    return (dispatch)=> {
        dispatch(GetListDocumentByFolder({
            parentId:folderId,
            pageIndex:pageIndex,
            pageSize:pageSize
        }));
    }
}

export const GetDocumentType = () => {
    return service.get(ApiUrl.DocumentType).then(response => { return response }).catch(error => { return error });
}

export const GetAllDocumentFolder = () => {
    const params = new URLSearchParams();
    return service.get(ApiUrl.GetAllDocumentFolder, params).then(response => { return response }).catch(error => { return error });
}

export const GetListDocumentByFolder = (data) => {
    return (dispatch)=> {
        const params = new URLSearchParams();
        params.append('parentId', data.parentId);
        params.append('pageIndex', data.pageIndex);
        params.append('pageSize', data.pageSize);
        params.append('documentName', data.documentName);

        data.name && params.append('name', data.name);
        data.documentTypeId && params.append('documentTypeId', data.documentTypeId);
        data.districtIds && params.append('districtIds', data.districtIds);
        data.years && params.append('years', data.years);

        if(data.actionType == 'search'){
            service.post(ApiUrl.SearchDocument, data)
                .then(response => {
                    handleDocResponse(dispatch,response);
                }).catch(error => {
                return error
            });
        }else{
            service.get(ApiUrl.GetListDocumentByFolder, params)
                .then(response => {
                    handleDocResponse(dispatch,response);
                }).catch(error => {
                return error
            });
        }
    }
}

const handleDocResponse = (dispatch,response) => {
    dispatch(saveListDocuments(response.content.documents.items));
    dispatch(saveListDocumentsPaging(response.content.documents));
    if(Array.isArray(response.content.breadCrums) && response.content.breadCrums.length >0){
        dispatch(saveListBredScrums(response.content.breadCrums));
    }else{
        dispatch(saveListBredScrums([{
            'id': 0,
            'title': 'Hồ sơ quy hoạch tỉnh Ninh Thuận'
        }]));
    }
}

export const SearchDocument = (data) => {
    const params = new URLSearchParams();
    params.append('pageIndex', data.pageIndex);
    params.append('pageSize', data.pageSize);
    data.name && params.append('name', data.name);
    data.documentTypeId && params.append('documentTypeId', data.documentTypeId);
    data.district && params.append('district', data.district);
    data.year && params.append('year', data.year);
    return service.get(ApiUrl.SearchDocument, params).then(response => { return response }).catch(error => { return error });
}

export const GetLockUpDistrict = () => {
    return service.get(ApiUrl.GetCmsLookupDistrict).then(response => { return response }).catch(error => { return error });
}
export const GetDocumentByPlanningId = (id) => {
    const params = new URLSearchParams();
    params.append('planningId', id);
    return service.get(ApiUrl.GetDocumentByPlanningId, params).then(response => { return response }).catch(error => { return error });
}
export const GetChildrenDocument = (planningId, parentId) => {
    const params = new URLSearchParams();
    params.append('planningId', planningId);
    params.append('parentId', parentId);
    return service.get(ApiUrl.GetChildrenDocument, params).then(response => { return response }).catch(error => { return error });
}
export const GetYearStatement = () => {
    return service.get(ApiUrl.GetYearStatement).then(response => { return response }).catch(error => { return error });
}
export const DownloadFile = (id) => {
    const params = new URLSearchParams();
    params.set('id', id);
    return service.getBinary(ApiUrl.DownloadFile, params).then(response => { return response }).catch(error => { return error });
}

const InitState = {
    selectedFolder: [],
    listDocuments:[],
    paginationNumber: [],
    folderId: 0,
};

export default function NinhThuanDocumentReducer(state = InitState, action) {
    switch (action.type) {
        case SAVE_SELECTED_FOLDER_ID:
            return { ...state, selectedFolder: action.data };
        case SAVE_LIST_DOCUMENTS:
            return { ...state, listDocuments: action.data };
        case SAVE_LIST_DOCUMENTS_PAGING:
            return { ...state, paginationNumber: action.data };
        case SAVE_LIST_BREDSCRUMS:
            return { ...state, listBredScrums: action.data };
        case SAVE_FOLDER_ID:
            return { ...state, folderId: action.data };
        case SAVE_DATA_SEARCH:
            return { ...state, dataSearch: action.data };
        default:
            return state;
    }
}