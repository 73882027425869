const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
    api: "https://ninhthuan-api.cgis.asia/",
    DomainUserSite:"http://localhost:3000",
    DomainAdminSite:"http://localhost:3001",
    DoMainNameGeoServer:"https://geo.cgis.asia/geoserver/wfs",
    DoMainNameGeoServerWMS:"https://geo.cgis.asia/geoserver/wms",
    DomainName:"",
  },
  production: {
    api: "https://ninhthuan-api.cgis.asia/",
    DomainUserSite:"https://ninhthuan.cgis.asia",
    DomainAdminSite:"https://admin.ninhthuan.cgis.asia",
    DoMainNameGeoServer:"https://geo.cgis.asia/geoserver/wfs",
    DoMainNameGeoServerWMS:"https://geo.cgis.asia/geoserver/wms",
    DomainName:"ninhthuan.cgis.asia",
  },
};

module.exports = apiEnvironment[env];
