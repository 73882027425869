/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faBook
} from "@fortawesome/free-solid-svg-icons";
import "./left-menu.scss";
import ViLanguages from "../../../languages/vi";
import { faMap } from "@fortawesome/free-regular-svg-icons";
import LeftMenuModels from "../../../models/planning-map-view/left-menu.model";
import {connect} from 'react-redux';
import LayerSettingsModels from "../../../models/map-data-model-b/layer-setting-models";
import { bindActionCreators } from "redux";
import * as MapDataStore from "../../../redux/store/map-data/map-data.store";
import {
  BoundariesViewModel, RelatedLayerModel,
} from "../../../models/map-data-model-b/new-planning-relation.model";

import SearchPlanningView from "./search-planning-name/search-planning-name.view";
import SearchByAddress from "./search-address/search-address.view";
import SearchByAddressButton from "./search-address/search-address-button.view";
import SearchByPosition from "./search-position/search-position.view";
import SearchByPositionButton from "./search-position/search-position-button.view";
import DocumentView from "./document/document.view"
import InfoPlanning from "./info/planning"
import InfoDocument from "./info/document"
import Economic from "./category/economic"
import Category from "./category/category"
import InfoNote from "./info/note"
import Related from "./related/related"
import Result from "./result/result"
import * as appActions from "../../../core/app.store";
import Collapsible from 'react-collapsible';
import {isMobile} from 'react-device-detect';
const LanguageCollects = ViLanguages;

function LeftMenuView(props: any) {
  const [isShowMapResult, setShowMapResult] = useState(false);
  useEffect(() => {
    if(isMobile){
      handleClickToggleLeftNavBar();
    }
  }, [props.headerTable]);

  function handleClickIconLeftNavbar(id: string) {
    handleClickToggleLeftNavBar();
    const buttonElement = document.querySelector(
      `button[aria-controls="${id}"]`
    ) as HTMLElement;
    if (buttonElement.classList.contains("collapsed")) buttonElement.click();
  }

  function handleClickToggleLeftNavBar() {
    props.toggleStateIsLeftNavbarHide();
    if (!props.isLeftNavbarHide) {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.add("hide-left-navbar");
    } else {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.remove("hide-left-navbar");
    }
  }



  function handleToggleDisplayBoundaries(
    boundariesObject: BoundariesViewModel,
    relatedLayerObject: RelatedLayerModel,
    isDisplay: boolean
  ) {
    props.openLayerController.toggleDisplayBoundariesRelation(
      boundariesObject,
      relatedLayerObject,
      isDisplay
    );
  }

  function handleToggleShowAllMapResult() {
    if (!isShowMapResult) {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.add("show-map-result");
      setShowMapResult(true);
    } else {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.remove("show-map-result");
      setShowMapResult(false);
    }
  }

  useEffect(() => {
    props.GetListPlanningRelationByPlanningId(props.planningId);
    props.GetLayerCategoryParent();
  }, []);
  
  function setMenuIconL2(menuId,status) {
      let iconDown = document.getElementById('level2-arrow-down'+menuId);
      let iconUp = document.getElementById('level2-arrow-up'+menuId);
      if(iconDown && iconUp){
          if(status == 'close'){
            iconDown.style.display = "block";
            iconUp.style.display = "none";
          }else{
            iconDown.style.display = "none";
            iconUp.style.display = "block";
          }
      }
  }
  
  return (
    <div
      className={
        "h-100 position-relative custom-container-left-menu" +
        (isShowMapResult ? " show-map-result" : "")
      }
    >
      <div className="navbar-custom h-100 position-relative" id="accordion">
        <div className="header-navbar-custom position-relative">
          <button
            className="btn "
            data-toggle="collapse"
            data-target="#mapLayersTab"
            aria-expanded="true"
            aria-controls="mapLayersTab"
          >
            {LanguageCollects.planningMapView_map}
          </button>
          <button
            id="ButtonControlMapInfomationTab"
            className="btn collapsed"
            data-toggle="collapse"
            data-target="#mapInfomationTab"
            aria-expanded="false"
            aria-controls="mapInfomationTab"
          >
            {LanguageCollects.planningMapView_infomation}
          </button>
          <button
              className="btn collapsed"
              data-toggle="collapse"
              data-target="#relativePlanningTab"
              aria-expanded="false"
              aria-controls="relativePlanningTab"
              hidden={props.isLeftNavbarHide}
          >
            {LanguageCollects.planningMapView_relatedPlanning}
          </button>
          <button
            id="btnRelativeResultTab"
            className="btn collapsed"
            data-toggle="collapse"
            data-target="#relativeResultTab"
            aria-expanded="false"
            aria-controls="relativeResultTab"
            hidden={props.isLeftNavbarHide}
          >
            {LanguageCollects.planningMapView_result}
          </button>
          <button
            className="btn btn-hide-left-navbar position-absolute"
            onClick={() => handleClickToggleLeftNavBar()}
          >
            <FontAwesomeIcon
              icon={props.isLeftNavbarHide ? faAngleRight : faAngleLeft}
              color="white"
              size="lg"
            />
          </button>
        </div>
        <div hidden={props.isLeftNavbarHide}>
          <div
            id="mapLayersTab"
            className="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div className="col-12 left-menu-search-by-planning">
              <SearchPlanningView
                  listGroupLayer={props.listGroupLayer}
                  toggleLeftNavBarAction={() => handleClickToggleLeftNavBar()}
              />
            </div>
            <div className="col-12 left-menu-search-by-planning">
              <div className="row no-gutters align-items-center">
                <div className="col-10">
                  <SearchByAddress />
                </div>
                <div className="col-2 text-right">
                  <SearchByAddressButton
                      toggleLeftNavBarAction={() => handleClickToggleLeftNavBar()}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 left-menu-search-by-position">
              <div className="row no-gutters align-items-center">
                <div className="col-10">
                  <SearchByPosition />
                </div>
                <div className="col-2 text-right">
                  <SearchByPositionButton
                      toggleLeftNavBarAction={() => handleClickToggleLeftNavBar()}
                  />
                </div>
              </div>
            </div>

            {
              Array.isArray(props.listLayerCategoryParent) && props.listLayerCategoryParent.length >0 && (
              props.listLayerCategoryParent.map((pItem,pId) => (
                  pItem && pItem.parentId == null && (
                  <div key={pItem.id}>
                    <div className="render-map-child-container pl-2 pr-2">
                      <details>
                        <summary className="mb-1 map-menu-level1" style={{ alignItems: "baseline", background: pItem.color}}>
                          {pItem.category_parent_name}
                          <img
                              className="position-absolute icon-dropdow right-position"
                              src={require("../../../assets/icon/arrow-up.png")}
                              alt="Arrow Down"
                          />
                          <img
                              className="position-absolute icon-dropright right-position"
                              src={require("../../../assets/icon/arrow-down.png")}
                              alt="Arrow Right"
                          />
                        </summary>
                        <div className="select-container">
                          {/* {
                            pItem.id && pItem.id == 2 && (
                                <Economic
                                    setMenuIconL2 = {(menuId,status) => {
                                      setMenuIconL2(menuId,status);
                                    }}
                                />
                            )
                          } */}
                          <Category
                              pItem = {pItem}
                              listLayerCategoryParent={props.listLayerCategoryParent}
                              listGroupLayer={props.listGroupLayer}
                              setMenuIconL2 = {(menuId,status) => {
                                setMenuIconL2(menuId,status);
                              }}
                              handleClickToggleLeftNavBar = {() => {
                                handleClickToggleLeftNavBar();
                              }}
                          />

                        </div>
                      </details>
                    </div>
                  </div>
                  )
              ))
              )
            }

            <DocumentView
                listGroupLayer={props.listGroupLayer}
            />
          </div>
          <div
            id="mapInfomationTab"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <InfoPlanning
                listMapInfomations = {props.listMapInfomations}
            />
            <InfoDocument />
            <InfoNote />
          </div>
          <div
              id="relativePlanningTab"
              className="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordion"
          >
            {props.listPlanningRelationShips?.map((data, index) => (
                <Related
                    handleClick={handleToggleDisplayBoundaries}
                    key={index}
                    data={data}
                />
            ))}
            {props.listPlanningRelationShips?.length === 0 && (
                <p className="p-3 text-center">
                  Không có quy hoạch liên quan nào được tìm thấy
                </p>
            )}
          </div>

          <div
            id="relativeResultTab"
            className="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordion"
          >
            {props.informationForTableResult &&
            props.informationForTableResult.length > 0 ? (
              <Result
                handleClick={handleToggleDisplayBoundaries}
                handleClickShowAllMapResult={handleToggleShowAllMapResult}
                data={props.informationForTableResult}
                isShowMapResult={isShowMapResult}
                headerTable={props.headerTable ? props.headerTable : []}
              />
            ) : (
              <p className="p-3 text-center">
                Không có dự án liên quan nào được tìm thấy
              </p>
            )}
          </div>
        </div>
        <div
          className="header-navbar-custom-icons-style text-center position-absolute"
          hidden={!props.isLeftNavbarHide}
        >
          <button
            title={LanguageCollects.planningMapView_map}
            onClick={() => handleClickIconLeftNavbar("mapLayersTab")}
          >
            <FontAwesomeIcon icon={faMap} color="#1075bd" size="lg" />
          </button>
          <button
            title={LanguageCollects.planningMapView_infomation}
            onClick={() => handleClickIconLeftNavbar("mapInfomationTab")}
          >
            <div className="information-circle-warp">
              <svg color="#1075bd"  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                  <path d="M12.1004 0C18.0004 0 23.1004 4.5 23.5004 10.5C24.0004 16.6 19.6004 21.9 13.6004 22.8C11.7004 23 9.80039 23 8.00039 22.3C7.60039 22.2 7.30039 22.2 7.00039 22.3C5.90039 22.8 4.80039 23.3 3.70039 23.9C3.20039 24.1 2.80039 24.1 2.50039 23.8C2.20039 23.5 2.10039 23.1 2.30039 22.6C2.70039 21.7 3.10039 20.7 3.60039 19.8C3.70039 19.6 3.70039 19.5 3.50039 19.3C1.40039 17 0.400391 14.2 0.400391 11.1C0.400391 5.9 4.70039 1.1 10.0004 0.1C10.7004 0 11.1004 0 12.1004 0ZM4.50039 21.8C5.20039 21.5 5.90039 21.2 6.60039 20.9C7.20039 20.6 7.90039 20.6 8.60039 20.8C10.2004 21.4 11.9004 21.4 13.6004 21.2C18.3004 20.5 22.3004 15.2 21.8004 10.6C21.4004 5.5 16.3004 1 10.8004 1.7C6.90039 2.3 4.00039 4.5 2.80039 8.3C1.60039 12 2.20039 15.4 4.80039 18.3C5.40039 19 5.50039 19.5 5.10039 20.3C4.90039 20.8 4.70039 21.3 4.50039 21.8ZM9.70039 9.3C9.40039 9.3 9.20039 9.5 9.20039 9.7C9.20039 9.9 9.40039 10.1 9.60039 10.2C9.70039 10.2 9.90039 10.2 10.0004 10.2C10.5004 10.2 10.8004 10.5 10.7004 11.1C10.6004 11.7 10.4004 12.4 10.3004 13C10.1004 14.1 9.90039 15.2 9.80039 16.3C9.70039 17 10.0004 17.6 10.6004 17.9C11.7004 18.5 13.5004 18.4 14.5004 17.6C14.7004 17.4 14.9004 17.2 15.0004 17C15.1004 16.9 15.1004 16.7 15.0004 16.6C14.9004 16.5 14.7004 16.6 14.6004 16.6C14.3004 16.7 14.1004 16.9 13.8004 16.9C13.3004 17.1 12.8004 16.7 12.9004 16.1C12.9004 16 12.9004 15.9 12.9004 15.8C13.3004 14 13.6004 12.2 14.0004 10.4C14.1004 9.7 13.8004 9.3 13.1004 9.3C12.6004 9.3 12.0004 9.3 11.5004 9.3C10.8004 9.3 10.2004 9.3 9.70039 9.3ZM12.5004 5.2C11.6004 5.2 10.8004 6 10.8004 6.9C10.8004 7.8 11.6004 8.6 12.5004 8.6C13.4004 8.6 14.2004 7.8 14.2004 6.9C14.2004 6 13.4004 5.2 12.5004 5.2Z" fill="#1075bd"/>
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="24" height="24" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </button>
          <button
            title={LanguageCollects.planningMapView_relatedPlanning}
            onClick={() => handleClickIconLeftNavbar("relativePlanningTab")}
          >
            <svg color="#1075bd" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
                <path d="M21.3996 4.5C21.3996 4.4 21.3996 4.4 21.3996 4.3V4.2C21.3996 4.1 21.2996 4 21.1996 3.9L17.4996 0.2C17.3996 0.1 17.2996 0.1 17.2996 0C17.2996 0 17.2996 0 17.1996 0C16.9996 0 16.9996 0 16.8996 0H6.39961C5.99961 0 5.59961 0.4 5.59961 0.9V3.7H3.49961C2.99961 3.7 2.59961 4.1 2.59961 4.6V23.2C2.59961 23.6 2.99961 24 3.49961 24H16.8996C17.3996 24 17.7996 23.6 17.7996 23.1C17.7996 22.6 17.3996 22.2 16.8996 22.2H4.29961V5.4H5.49961V20.1C5.59961 20.6 5.99961 21 6.39961 21H20.4996C20.9996 21 21.3996 20.6 21.3996 20.1V4.5ZM19.6996 4.9H16.4996V1.7L19.6996 4.9ZM7.29961 19.3V1.7H14.6996V5.8C14.6996 6.3 15.0996 6.7 15.5996 6.7H19.6996V19.3H7.29961Z" fill="#1075bd"/>
                <path d="M16.8008 9H10.1008C9.70078 9 9.30078 9.4 9.30078 9.9C9.30078 10.4 9.70078 10.8 10.2008 10.8H16.9008C17.4008 10.8 17.8008 10.4 17.8008 9.9C17.7008 9.4 17.3008 9 16.8008 9Z" fill="#11998E"/>
                <path d="M16.7992 12H10.0992C9.59922 12 9.19922 12.4 9.19922 12.9C9.19922 13.4 9.59922 13.8 10.0992 13.8H16.7992C17.2992 13.8 17.6992 13.4 17.6992 12.9C17.6992 12.4 17.2992 12 16.7992 12Z" fill="#11998E"/>
                <path d="M16.7992 15H10.0992C9.59922 15 9.19922 15.4 9.19922 15.9C9.19922 16.4 9.59922 16.8 10.0992 16.8H16.7992C17.2992 16.8 17.6992 16.4 17.6992 15.9C17.6992 15.4 17.2992 15 16.7992 15Z" fill="#11998E"/>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>

          </button>
        </div>
      </div>
      
    </div>
  );
}


const mapStateToProps = (state: any) => ({
  listMapInfomations: state.mapData.infomations,
  informationForTableResult: state.mapData.dataForTable,
  listPlanningRelationShips: state.mapData.planningRelationShips,
  openLayerController: state.openlayer.openLayerController,
  listLayerCategoryParent: state.mapData.layerParentList,
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      GetListPlanningRelationByPlanningId:MapDataStore.GetListPlanningRelationByPlanningId,
      GetLayerCategoryParent:MapDataStore.GetLayerCategoryParent,
      showLoading: appActions.ShowLoading,
    },dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenuView);
