import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faVideo,
  faFilm,
  faEye,
  faCamera,
  faQuestionCircle,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import { faNewspaper, faEnvelope } from "@fortawesome/free-regular-svg-icons";

export default function OtherLinkView() {
  return (
      <div className="menu-item-link">
        <ul className="list-unstyled">
          <li>
            <a href="http://www.ninhthuan.gov.vn/Pages/default.aspx" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </span>
              Cổng thông tin điện tử
            </a>
          </li>
          <li>
            <a href="http://ninhthuantv.vn/trangchu/1.aspx" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faVideo} />
                </span>
              Truyền hình Ninh Thuận
            </a>
          </li>
          <li>
            <a href="http://ninhthuantv.vn/trangchu/1.aspx" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faVideo} />
                </span>
              Phát thanh Ninh Thuận
            </a>
          </li>
          <li>
            <a href="http://baoninhthuan.com.vn/" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faNewspaper} />
                </span>
              Báo Ninh Thuận
            </a>
          </li>
          <li>
            <a href="https://ninhthuantourism.vn/" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faEye} />
                </span>
              Du lịch Ninh Thuận
            </a>
          </li>
          <li>
            <a href="http://www.ninhthuan.gov.vn/Pages/Lich-cong-tac-tuan.aspx" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </span>
              Lịch công tác
            </a>
          </li>
          <li>
            <a href="http://hoidap.ninhthuan.gov.vn/portal/faq/Pages/chuyen-trang-hoi-dap.aspx" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
              Hỏi đáp trực tuyến
            </a>
          </li>
          <li>
            <a href="http://dichvucong.ninhthuan.gov.vn/portaldvc/KenhTin/van-ban.aspx" target="_blank" >
                <span>
                  <FontAwesomeIcon icon={faBook} />
                </span>
              Văn bản pháp quy
            </a>
          </li>
          <li>
            <a href="http://vbdh.ninhthuan.gov.vn/vbdh.nsf" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faBook} />
                </span>
              VB chỉ đạo điều hành
            </a>
          </li>
          <li>
            <a href="http://dichvucong.ninhthuan.gov.vn/portaldvc/KenhTin/danh-gia-su-hai-long.aspx" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
              Hòm thư góp ý
            </a>
          </li>
        </ul>
      </div>
  );
}
