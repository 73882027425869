import { Map } from "immutable";
import { DecodeToken } from "./jwt-token-helper";
import Cookies from "universal-cookie";
import history from "../common/history";
import ShowNotification from "../components/react-notifications/react-notifications";
import viVN from "../languages/vi";
import * as apiConfig from "../api/api-config";

let configuration = Map();

export const DomainUserSite = apiConfig.DomainUserSite;
export const DomainAdminSite = apiConfig.DomainAdminSite;
const domainName = apiConfig.DomainName;
export const DoMainNameGeoServer = apiConfig.DoMainNameGeoServer;
export const DoMainNameGeoServerWMS = apiConfig.DoMainNameGeoServerWMS;


export const ApiServerKey = {
  APP_API_ROOT: "API_ROOT",
};

export const TokenPrefix = "Bearer";
export const APIUrlDefault = apiConfig.api;
export const GoogleMapAPI = "AIzaSyDWwoJJa55rOg2D7o-30ISBWZMvzUPvT3Q";
export const libraries = ["places"];
export const region = "vi_VN";
export const language = "vi_VN";

//--- Cookies
const cookies = new Cookies();
const dateExpires = new Date();
dateExpires.setTime(dateExpires.getTime() + 720 * 60 * 60 * 1000);

export function setCookies(
  name,
  value,
  options = { path: "/", domain: domainName, expires: dateExpires }
) {
  cookies.set(name, value, options);
}

export function getCookies(name) {
  return cookies.get(name);
}

export function removeCookies(
  name,
  options = { path: "/", domain: domainName }
) {
  cookies.remove(name, options);
}

export function removeListCookies(nameList) {
  if (nameList instanceof Array) {
    nameList.map((name) => {
      cookies.remove(name, { path: "/", domain: domainName });
      cookies.remove(name, { path: "/", domain: window.location.host });
    });
  }
}

export function setConfiguration(name, value) {
  configuration = configuration.set(name, value);
}

export function getConfiguration(key) {
  if (!configuration.has(key)) {
    throw new Error("Undefined configuration key: " + key);
  }

  return configuration.get(key);
}

export function onRemoveTokens(tokens) {
  return Promise.resolve(onRemoveTokenKeys(tokens));
}

function onRemoveTokenKeys(tokens) {
  if (tokens && tokens.length > 0) {
    tokens.map((t) => localStorage.removeItem(t));
  }
}

export function getUserInfo() {
  let userInfoToken = getCookies(TokenKey.token);
  let userInfo = DecodeToken(userInfoToken);
  if (userInfo) {
    return userInfo;
  }

  return null;
}

export const NotificationMessageType = {
  Success: "success",
  Warning: "warning",
  Error: "error",
};

export const NotificationPosition = {
  TopLeft: "top-left",
  TopRight: "top-right",
  TopCenter: "top-center",
  Center: "center",
  BottomLeft: "bottom-left",
  BottomRight: "bottom-right",
  BottomCenter: "bottom-center",
};

export const TokenKey = {
  token: "token",
  returnUrl: "returnUrl",
};

export function changeAlias(alias) {
  var str = alias;
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  // str = str.replace(/ + /g, " ");
  str = str.toLowerCase();
  str = str.trim();
  return str;
}

export function HandleError(error) {
  if (error && error.errorType && error.errorType === "authen") {
    history.push("dang-nhap");
  }
  ShowNotification(
    viVN.Error[
      error && error.errorType ? error.errorType : "UnableHandleException"
    ],
    NotificationMessageType.Error
  );
}

const FileExtensionsPreviewSupport = [
  "png",
  "jpg",
  "xls",
  "xlsx",
  "doc",
  "docx",
  "ppt",
  "pptx",
  "pdf",
];

export const ImageSupport = [
  "png",
  "jpg",
  "gif",
  "PNG",
  "JPG",
  "GIF",
];

export const defaultPagesize = 8;

export function FilePreviewSupport(fileType) {
  if (!fileType) return false;
  return FileExtensionsPreviewSupport.some(
    (fileExt) => fileExt.toUpperCase() == fileType.toUpperCase()
  );
}

export const FILE_EXTENSION_ICON = {
  DOC: "doc",
  DOCX: "docx",
  PPT: "ppt",
  PDF: "pdf",
  XLSX: "xlsx",
  TIF: "tif",
  DWG: "dwg",
  DNG: "dng",
  PNG: "png",
  JPG: "jpg",
  JPEG: "jpeg",
  XLS: "xls",
  PPTX: "pptx",
  TXT: "txt",
};
